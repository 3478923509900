import React from 'react';

const PhoneCallComponent = () => {
  const phoneNumber = '07662709896';
  const countrycodephonenumber = '+27662709896'
  const handlePhoneCallClick = () => {
    window.location.href = `tel:${countrycodephonenumber}`;
  };

  return (
    <div>
        
      <a href={`tel:${phoneNumber}`} onClick={handlePhoneCallClick} className="btn-get-started animate__animated animate__fadeInUp">
      <i className="bi bi-telephone me-2"></i> {phoneNumber}
      </a>
    </div>
  );
};

export default PhoneCallComponent;
