
import "./ServiceSection.css";
import { Container, Card, Row, Col} from "react-bootstrap";
import service1 from '../../assets/img/services/service-1.jpg';
import service2 from '../../assets/img/services/service-2.jpg';
import service3 from '../../assets/img/services/service-3.jpg';
import service4 from '../../assets/img/services/service-4.jpg';
import service5 from '../../assets/img/services/service-5.jpg';
import service6 from '../../assets/img/services/service-6.jpg';
import HeroWave from "../others/HeroWave";

const ServiceSection = () => {
return(
    <>
    <section id="services" className="services section-bg">
      <Container>

        <div className="section-title">
          <h2 data-aos="fade-in">Services</h2>
        </div>
        <Row>
          <Col sm={4} data-aos="fade-right">
            <Card style={{position: 'relative', background: 'transparent', height: '600px'}} className="w-100 border-0 mb-3">
              <Card.Body className="maincontainer p-0 w-100">
              <div class="back w-100 border shadow bg-white">
                <div className="p-3">
                 <Card.Title>Borehole drilling &amp; Machinery services</Card.Title>
                 
                  <Card.Text className="mb-2"> Site evaluation and feasibility studies to determine the best location and depth for drilling.</Card.Text>
                  <Card.Text className="mb-2"> Installation of borehole pumps, casings, screens, and other necessary equipment.</Card.Text>
                  <Card.Text className="mb-2"> Maintenance and repair services for existing boreholes and pumping systems.</Card.Text>
                  <Card.Text className="mb-2"> Water quality testing and analysis to ensure the suitability of the water supply.</Card.Text>
                  <Card.Text className="mb-2"> SSustainable drilling practices to minimize environmental impact and conserve water resources.</Card.Text>
                  <Card.Text className="mb-2"> Customized solutions tailored to the specific needs and requirements of each client or project.</Card.Text>
                  </div>
                  <div className="wavesBg" style={{background: '#f57813'}}><HeroWave/></div>
               </div>
                <div class="front w-100 border bg-white shadow">
                      <Card.Img variant="top" src={service1} alt="Borehole drilling &amp; Machinery services"/>
                      <div className="p-3">
                        <Card.Title>Borehole drilling &amp; Machinery services</Card.Title>
                        <Card.Text className="mb-2">Borehole drilling for various purposes such as residential, commercial, industrial, and agricultural applications.</Card.Text>  
                  <Card.Text className="mb-2">Well-equipped machinery and drilling rigs for efficient and precise drilling operations.</Card.Text>
                  <Card.Text className="mb-2"> Experienced team of drilling professionals knowledgeable in geology and hydrogeology.</Card.Text>
                      </div>
                      <div className="wavesBg" style={{background: '#f57813'}}><HeroWave/></div>
                </div>
              
              </Card.Body>
            </Card>
          </Col>
          <Col sm={4} data-aos="fade-left">
          <Card style={{position: 'relative', background: 'transparent', height: '600px'}} className="w-100 border-0 mb-3">
              <Card.Body className="maincontainer p-0 w-100">
              <div class="back w-100 border shadow bg-white">
                <div className="p-3">
                <Card.Title>Borehole &amp; Pump Repairs &amp; Maintainance</Card.Title>
                
                  <Card.Text className="mb-2"> Prompt response to emergency repair calls to minimize downtime and water supply interruptions.</Card.Text>
                  <Card.Text className="mb-2"> Replacement of worn-out or damaged components such as pump motors, pipes, valves, and control panels.</Card.Text>
                  <Card.Text className="mb-2"> Troubleshooting and troubleshooting of electrical, mechanical, and hydraulic issues.</Card.Text>
                  <Card.Text className="mb-2"> Compliance with industry standards and regulations for safe and sustainable borehole operation.</Card.Text>
                </div>
                <div className="wavesBg" style={{background: '#15a04a'}}><HeroWave/></div>
              </div>
              <div class="front w-100 border bg-white">
                      <Card.Img variant="top" src={service2} alt="Borehole drilling &amp; Machinery services"/>
                      <div className="p-3">
                        <Card.Title>Borehole &amp; Pump Repairs &amp; Maintainance</Card.Title>
                        <Card.Text className="mb-2"> Comprehensive repair and maintenance services for boreholes and pumping systems.</Card.Text>
                  <Card.Text className="mb-2"> Experienced technicians skilled in diagnosing and resolving issues with borehole pumps and associated equipment.</Card.Text>
                  <Card.Text className="mb-2"> Regular maintenance programs to ensure optimal performance and longevity of borehole systems.</Card.Text>
                      </div>
                     <div className="wavesBg" style={{background: '#15a04a'}}><HeroWave/></div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={4} data-aos="fade-right">
            <Card style={{position: 'relative', background: 'transparent', height: '600px'}} className="w-100 border-0 mb-3">
              <Card.Body className="maincontainer p-0 w-100">
                  <div class="back w-100 border shadow bg-white">
                    <div className="p-3">
                      <Card.Title><span>Watertank Installations</span></Card.Title>
                      <Card.Text className="mb-2"> Connection of water tanks to existing plumbing systems, including guttering, downpipes, and pumps, to ensure proper water collection and distribution.</Card.Text>
                      <Card.Text className="mb-2"> Integration of filtration and purification systems for potable water tanks to ensure water quality and safety.</Card.Text>
                      <Card.Text className="mb-2">Implementation of overflow and drainage systems to prevent waterlogging and structural damage around the tank area.</Card.Text>
                      <Card.Text className="mb-2">Collaboration with other contractors, such as plumbers and landscapers, to coordinate installations as part of larger construction projects.</Card.Text>
                    </div>
                   <div className="wavesBg" style={{background: '#d90769'}}><HeroWave/></div>
                  </div>
                  <div class="front w-100 border bg-white">
                      <Card.Img variant="top" src={service3} alt="Borehole drilling &amp; Machinery services"/>
                      <div className="p-3">
                        <Card.Title>Watertank Installations</Card.Title>
                        <Card.Text className="mb-2"> Expert installation of water tanks for residential, commercial, and industrial applications.</Card.Text>
                    <Card.Text className="mb-2"> Assessment of site suitability and selection of appropriate water tank size and type based on water usage needs and space availability.</Card.Text>
                    <Card.Text className="mb-2"> Installation of both above-ground and underground water tanks using industry-standard techniques and equipment.</Card.Text>
                      </div>
                     <div className="wavesBg" style={{background: '#d90769'}}><HeroWave/></div>
                  </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-0 mt-md-5">
          <Col sm={4} data-aos="fade-left">
          <Card style={{position: 'relative', background: 'transparent', height: '650px'}} className="w-100 border-0 mb-3">
              <Card.Body className="maincontainer p-0 w-100">
              <div class="back w-100 border shadow bg-white">
                    <div className="p-3">
                  <Card.Title><span> Rainwater Harvesting Systems</span></Card.Title>
                  <Card.Text className="mb-2"> Integration of first flush diverters and filtration systems to remove debris and contaminants from collected rainwater before storage.</Card.Text>
                  <Card.Text className="mb-2"> Installation of storage tanks in above-ground or underground locations, taking into account space availability and aesthetic considerations.</Card.Text>
                  <Card.Text className="mb-2">Connection of rainwater harvesting systems to existing plumbing for non-potable water uses such as irrigation, toilet flushing, and laundry.</Card.Text>
                  <Card.Text className="mb-2">Implementation of overflow and drainage mechanisms to prevent flooding and waterlogging during heavy rainfall events.</Card.Text>
                  <Card.Text className="mb-2">Regular maintenance and cleaning of rainwater harvesting components to ensure optimal system performance and water quality.</Card.Text>
                 </div>
                <div className="wavesBg" style={{background: '#15bfbc'}}><HeroWave/></div>
                </div>
                <div class="front w-100 border bg-white">
                      <Card.Img variant="top" src={service4} alt="Borehole drilling &amp; Machinery services"/>
                      <div className="p-3">
                        <Card.Title>Rainwater Harvesting Systems</Card.Title>
                        <Card.Text className="mb-2"> Design and installation of rainwater harvesting systems for residential, commercial, and agricultural properties.</Card.Text>
                        <Card.Text className="mb-2"> Assessment of site suitability and calculation of rainwater collection potential based on factors such as roof area, rainfall patterns, and local regulations.</Card.Text>
                        <Card.Text className="mb-2"> Selection of appropriate components for the rainwater harvesting system, including gutters, downspouts, filters, storage tanks, and pumps.</Card.Text>
                      </div>
                     <div className="wavesBg" style={{background: '#15bfbc'}}><HeroWave/></div>
                  </div>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={4} data-aos="fade-left">
          <Card style={{position: 'relative', background: 'transparent', height: '650px'}} className="w-100 border-0 mb-3">
              <Card.Body className="maincontainer p-0 w-100">
              <div class="back w-100 border shadow bg-white">
                    <div className="p-3">
                <Card.Title><span>  Agriculture Irrigation System</span></Card.Title>
                 
                  <Card.Text className="mb-2"> Installation of irrigation infrastructure, including pipes, valves, pumps, filters, and controllers, to deliver water efficiently to crops while minimizing waste and runoff.</Card.Text>
                  <Card.Text className="mb-2"> Integration of smart irrigation technologies such as soil moisture sensors, weather data monitoring, and remote control systems to optimize water use and scheduling.</Card.Text>
                  <Card.Text className="mb-2">Implementation of fertigation systems to deliver fertilizers and nutrients directly to plants through the irrigation system, promoting healthy growth and maximizing crop yields.</Card.Text>
                  <Card.Text className="mb-2">Compliance with local regulations and guidelines for agricultural water use, including permits, water rights, and environmental conservation measures.</Card.Text>
                </div>
               <div className="wavesBg" style={{background: '#f5cf13'}}><HeroWave/></div>
                </div>
                <div class="front w-100 border bg-white">
                      <Card.Img variant="top" src={service5} alt="Borehole drilling &amp; Machinery services"/>
                      <div className="p-3">
                        <Card.Title>Agriculture Irrigation System</Card.Title>
                        <Card.Text className="mb-2"> Design and installation of custom irrigation systems tailored to the specific needs of agricultural crops and landscapes.</Card.Text>
                  <Card.Text className="mb-2"> Assessment of soil types, crop water requirements, topography, and climate conditions to determine the most efficient irrigation methods and technologies.</Card.Text>
                  <Card.Text className="mb-2"> Selection of appropriate irrigation techniques, including drip irrigation, sprinkler systems, center pivot systems, and flood irrigation, based on factors such as water availability, crop type, and field size.</Card.Text>
                      </div>
                     <div className="wavesBg" style={{background: '#f5cf13'}}><HeroWave/></div>
                  </div>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={4} data-aos="fade-left">
          <Card style={{position: 'relative', background: 'transparent', height: '650px'}} className="w-100 border-0 mb-3">
              <Card.Body className="maincontainer p-0 w-100">
              <div class="back w-100 border shadow bg-white">
                    <div className="p-3">
                <Card.Title><span>Borehole Rehabilitation</span></Card.Title>
                
                  <Card.Text className="mb-2">  Conducting pumping tests to evaluate the borehole's performance and water yield after rehabilitation.</Card.Text>
                  <Card.Text className="mb-2">Enhancing the borehole's efficiency by redeveloping or flushing out the borehole to improve water flow and quality.</Card.Text>
                  <Card.Text className="mb-2"> Installing new components such as pumps, valves, or controls to upgrade the borehole system if necessary.</Card.Text>
                  <Card.Text className="mb-2">Implementing monitoring systems and regular maintenance schedules to prevent future deterioration and ensure long-term performance.</Card.Text>
                  <Card.Text className="mb-2">Ensuring compliance with local regulations and standards for borehole rehabilitation activities.</Card.Text>
                </div>
               <div className="wavesBg" style={{background: '#1335f5'}}><HeroWave/></div>
                </div>
                <div class="front w-100 border bg-white">
                      <Card.Img variant="top" src={service6} alt="Borehole drilling &amp; Machinery services"/>
                      <div className="p-3">
                        <Card.Title>Borehole Rehabilitation</Card.Title>
                        <Card.Text className="mb-2"> Thorough inspection and assessment of the existing borehole to identify any issues or deterioration.</Card.Text>
                  <Card.Text className="mb-2"> Removal of accumulated sediments, debris, or mineral deposits that may be obstructing water flow.</Card.Text>
                  <Card.Text className="mb-2"> Addressing any damages or leaks in the borehole casing, pipes, or screens to restore functionality.</Card.Text>
                      </div>
                     <div className="wavesBg" style={{background: '#1335f5'}}><HeroWave/></div>
                  </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
    </>
)
}
export default ServiceSection;