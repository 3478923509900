import React from "react";
import { NavLink } from "react-bootstrap";
import HeroWave from "../others/HeroWave";
import './FooterSection.css'
const Footer = () => {
  const phoneNumber = '+27662709896'; // Replace with the actual phone number
  const whatsappUrl = `https://wa.me/${phoneNumber}`;
  const youtubeUrl = 'https://www.youtube.com/@ayobaboreholes';
    return(
        <footer id="footer">
          <div style={{rotate: '180deg'}}>
            <HeroWave/>
          </div>
        <div className="container">
          <h3>Ayoba boreholes and water solutions</h3>
          <p>Unveiling Ayoba boreholes and water solutions - Your Premier Borehole Drilling Partner.</p>
          <div className="social-links">
            <NavLink href="#" className="twitter"><i className="bx bxl-twitter"></i></NavLink>
            <NavLink href="https://www.facebook.com/profile.php?id=61556563385864" target="_blank" className="facebook"><i className="bx bxl-facebook"></i></NavLink>
            <NavLink href="#" className="instagram"><i className="bx bxl-instagram"></i></NavLink>
            <NavLink href={whatsappUrl} className="google-plus"><i className="bx bxl-whatsapp"></i></NavLink>
            <NavLink href={youtubeUrl} className="youtube" target="_blank"><i className="bx bxl-youtube"></i></NavLink>
          </div>
          <div className="copyright">
            &copy; Copyright 2024, <strong><span>Ayoba boreholes and water solutions</span></strong>. All Rights Reserved
          </div>
        </div>
      </footer>
    );
}
export default Footer;