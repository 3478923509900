import React, {useState, useRef} from "react";
import { Form, Row } from "react-bootstrap";
import './ContactSection.css';
import axios from "axios";
import { toast } from "react-toastify";

const ContactusSection = () => {
  const submitbutton = {
    background: '#4680ff',
    border: '0',
    padding: '10px 24px',
    color: '#fff',
    transition: '0.4s',
    borderRadius: '50px',
  }
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const subjectRef = useRef(null);
  const messageRef = useRef(null);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
      });
      const [formErrors, setFormErrors] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
      });
      const handleValidation = () => {
        nameRef.current.focus();
        emailRef.current.focus();
        messageRef.current.focus();
        subjectRef.current.focus();

          let formIsValid = true;
          const errors = { ...formErrors };

        if (!formData.name.trim()) {
          errors.name = 'Name is required';
          formIsValid = false;
        }else if (!/^[a-zA-Z -]+$/.test(formData.name)) {
            errors.name = "name is not valid. Only characters A-Z, a-z and '-' are acceptable.";
            formIsValid = false;
        }
        if (!formData.email.trim()) {
          errors.email = 'Email is required';
          formIsValid = false;
        } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
          errors.email = 'Email is invalid';
          formIsValid = false;
        }
        if (!formData.subject.trim()) {
          errors.subject = 'Subject is required';
          formIsValid = false;
        } 
        if (!formData.message.trim()) {
          errors.message = 'Message is required';
          formIsValid = false;
        }
        setFormErrors(errors);
         return formIsValid;

      };
 
  
      const handleSubmit = async (e) => {
        e.preventDefault();
        if (!handleValidation()) {
          return;
        }
        console.log(formData);
                  var text = `Name: ${formData.name}\n` +
                 `Email: ${formData.email}\n` +
                 `Message: ${formData.message}\n` +
                 `Subject: ${formData.subject}`
  
         console.log(text);     
    const from = "gautengboreholes@gmail.com";
    const subject = "Registration Acknowledgment";
    const data_js = {
        access_token: "mj5semzvfk8mt07ditzg98o4", 
        from: from,
        subject: subject,
        text: text,
    };
  
    try {
        const response = await axios.post("https://postmail.invotes.com/send", data_js, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
  
        // Handling the response
        if (response.status === 200) {
            toast('Email sent successfully:', response.data);
            setFormData({
              name: '',
              email: '',
              subject: '',
              message: '',
          });
        } else {
            toast('Failed to send email. Status:', response.status);
           
        }
    } catch (error) {
        toast('Error sending email:', error);
       
    } 
      };
    
    
      
    
      const handleInputChange = (e) => {
        const { name, value } = e.target;
    
        setFormData({
          ...formData,
          [name]: value,
        });
        setFormErrors({
          ...formErrors,
          [name]: "",
        });
      };
   
      const phoneNumber = '07662709896';
      const countrycodephonenumber = '+27662709896'

      const handlePhoneCallClick = () => {
        window.location.href = `tel:${countrycodephonenumber}`;
      };
    return(
        <section id="contact" className="contact">
        <div className="container">
  
          <div className="section-title" data-aos="zoom-out">
            <h2>Contact</h2>
          </div>
  
          <div className="row mt-5">
  
            <div className="col-lg-4" data-aos="fade-right">
              <div className="info">
                <div className="address">
                  <i className="bi bi-geo-alt"></i>
                  <h4>Location:</h4>
                  <p>Gauteng, South Africa</p>
                </div>
  
                <div className="email">
                  <i className="bi bi-envelope"></i>
                  <h4>Email:</h4>
                  <p><a href="mailto:steve@ayobaboreholes.co.za">steve@ayobaboreholes.co.za</a></p>
                </div>
  
                <div className="phone">
                  <i className="bi bi-phone"></i>
                  <h4>Call:</h4>
                  <p onClick={handlePhoneCallClick} style={{cursor: 'pointer'}}>{phoneNumber}</p>
                </div>
  
              </div>
  
            </div>
  
            <div className="col-lg-8 mt-5 mt-lg-0" data-aos="fade-left">
  
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Form.Group className="col-md-6">
                    <Form.Control type="text" 
                      name="name" 
                      id="name" 
                      placeholder="Your Name" 
                      value={formData.name}
                      onChange={handleInputChange}
                      ref={nameRef}/>
                        <small id='nameHelp' className='text-danger'>
                            {formErrors.name}
                        </small>
                   
                  </Form.Group>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <input type="email" 
                      className="form-control" 
                      name="email" 
                      id="email" 
                      placeholder="Your Email" 
                      value={formData.email}
                      onChange={handleInputChange}
                      ref={emailRef}/>
                       {formErrors.email && <div className="text-danger">{formErrors.email}</div>}
                  </div>
                </Row>
                <div className="form-group mt-3">
                  <input type="text" 
                    className="form-control" 
                    name="subject" 
                    id="subject" 
                    placeholder="Subject" 
                    value={formData.subject}
                    onChange={handleInputChange}
                    ref={subjectRef}/>
                    {formErrors.subject && <div className="text-danger">{formErrors.subject}</div>}
                </div>
                <div className="form-group mt-3">
                  <textarea className="form-control" name="message" rows="5" placeholder="Message"
                   value={formData.message}
                   onChange={handleInputChange}
                   ref={messageRef}></textarea>
                   {formErrors.message && <div className="text-danger">{formErrors.message}</div>}
                </div>
                <div className="text-center mt-3"><button type="submit" style={submitbutton}>Send Message</button></div>
              </Form>
  
            </div>
  
          </div>
  
        </div>
      </section>
    );
}
export default ContactusSection;