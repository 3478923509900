import React from "react";
import './AboutSection.css';

const AboutSection = () => {
return(
    <section id="about" className="about">
    <div className="container">

      <div className="row gy-4">
        <div className="image col-xl-5"></div>
        <div className="col-xl-7">
          <div className="content d-flex flex-column justify-content-center ps-0 ps-xl-4">
            <h3 data-aos="fade-in" data-aos-delay="100" style={{fontWeight: '500'}}>Unveiling Ayoba boreholes and water solutions - Your Premier Borehole Drilling Partner</h3>
            <p data-aos="fade-in" className="mb-3">
            Ayoba boreholes and water solutions is a renowned borehole drilling company with a rich history of excellence spanning 10 years. Established with a vision to revolutionize the drilling industry, we have emerged as a trusted partner for clients across various sectors, including agriculture, construction, mining, and environmental exploration.  
            </p>
            <p data-aos="fade-in">
              Welcome to Ayoba boreholes and water solutions, where expertise meets innovation in the realm of borehole drilling services.
               As a leading provider in the industry, we pride ourselves on our commitment to delivering reliable, efficient, 
               and sustainable solutions to meet the diverse needs of our clients. 
               Join us on a journey as we explore the capabilities, values, and accomplishments that set Ayoba boreholes and water solutions apart 
               in the world of borehole drilling.
              </p>
            <div className="row gy-4 mt-3">
              <div className="col-md-6 icon-box" data-aos="fade-up">
              <i className="bi bi-award"></i>
                <h4><span>Excellence</span></h4>
                <p>At Ayoba boreholes and water solutions, we strive for excellence in every aspect of our operations. From initial project planning to drilling execution and beyond,
                     we uphold the highest standards of quality, precision, and professionalism.</p>
              </div>
              <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
              <i className="bi bi-brilliance"></i>
                <h4><span>Innovation</span></h4>
                <p>Innovation is at the heart of what we do. We embrace cutting-edge technologies, state-of-the-art equipment, and advanced drilling techniques to optimize efficiency, 
                    minimize environmental impact, and deliver superior results.</p>
              </div>
              <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
              <i className="bi bi-person-check"></i>
                <h4><span>Integrity</span></h4>
                <p> Integrity is the cornerstone of our business philosophy. We prioritize transparency, honesty, 
                    and ethical conduct in all our dealings,
                     fostering trust and long-term partnerships with our clients, stakeholders, and communities.</p>
              </div>
             
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
)
}
export default AboutSection;