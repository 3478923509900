
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/vendor/animate.css/animate.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'boxicons/css/boxicons.min.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import MainSection from './Components/MainSection';

function App() {
  return (
   <>
      <MainSection/>
      <ToastContainer/>
   </>
  );
}

export default App;
