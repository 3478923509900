import React from "react";
import './FeatureSection.css';
import bg_water from '../../assets/img/bg_water.svg';
const FeatureSection = () => {
    return(
    <section id="drillingProcess" className="services" style={{background: `url(${bg_water}) no-repeat 100% 100% /cover`}}>
      <div className="container">

        <div className="section-title" data-aos="zoom-out">
          <h2>Drilling Process</h2>
          <p></p>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="icon-box" data-aos="zoom-in-left">
              <div className="icon"><i className="bi bi-layer-backward"></i></div>
              <h4 className="title">Site analysis</h4>
              <p className="description">we facilitate to involves a systematic approach, tailored to the specific requirements and geological conditions of the site.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mt-5 mt-md-0">
            <div className="icon-box" data-aos="zoom-in-left" data-aos-delay="100">
              <div className="icon"><i className="bi bi-card-checklist"></i></div>
              <h4 className="title"><span>Assessment</span></h4>
              <p className="description">we provide initial surveys and geophysical assessments are conducted to determine the optimal location and depth for drilling.</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 mt-5 mt-lg-0 ">
            <div className="icon-box" data-aos="zoom-in-left" data-aos-delay="200">
              <div className="icon"><i className="bi bi-card-checklist"></i></div>
              <h4 className="title"><span>Drilling Techniques</span></h4>
              <p className="description">we are using a specialized drilling rigs equipped with various drilling techniques, such as rotary, percussion, or auger drilling, are deployed based on the geological composition. </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mt-5">
            <div className="icon-box" data-aos="zoom-in-left" data-aos-delay="300">
              <div className="icon"><i className="bi bi-shield-check"></i></div>
              <h4 className="title"><span href="">Preventive Cautions</span></h4>
              <p className="description">As drilling progresses, casing and grouting are employed to stabilize the borehole and prevent contamination of water sources. </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 mt-5">
            <div className="icon-box" data-aos="zoom-in-left" data-aos-delay="400">
              <div className="icon"><i className="bi bi-moisture"></i></div>
              <h4 className="title"><span href="">Water Analysis</span></h4>
              <p className="description">when reaching the desired depth, testing and analysis are conducted to evaluate the yield, quality, and sustainability of the extracted resources.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mt-5">
            <div className="icon-box" data-aos="zoom-in-left" data-aos-delay="500">
              <div className="icon"><i className="bi bi-clock"></i></div>
              <h4 className="title"><span href="">Pump Installation</span></h4>
              <p className="description">
              Pump installation involves the careful selection, placement, and configuration of pumps to efficiently extract water from boreholes, wells, or other water sources.</p>
            </div>
          </div>
        </div>

      </div>
    </section>
    )
}
export default FeatureSection;