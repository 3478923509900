import React from 'react';
import whatsapp from '../../assets/img/icons8-whatsapp.svg';
import { Image } from 'react-bootstrap';
const WhatsAppButton = () => {
  const phoneNumber = '+27662709896'; // Replace with the actual phone number
  const whatsappUrl = `https://wa.me/${phoneNumber}`;

  return (
    <div>
      <a href={whatsappUrl} target="_blank" className="btn-get-started animate__animated animate_fadeInUp" rel="noopener noreferrer">
      <Image src={whatsapp} style={{width: '20px', height: '20px'}} className='me-2'></Image> Give us a message
      </a>
    </div>
  );
};

export default WhatsAppButton;