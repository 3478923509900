import React, {useEffect, useRef} from "react";
import './HeroSection.css';
import MyVideo from '../../assets/video/istockphoto-1144833953-640_adpp_is.mp4';
import HeroWave from "../others/HeroWave";
import logo from '../../assets/img/TruckImg.png';
import { Image } from "react-bootstrap";
const HeroSection = ({ lineBG }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, []);
    return(
    <section id="hero" class="hero d-flex align-items-center">
      <video ref={videoRef} muted loop id="myVideo" className="banner-video">
      <source src={MyVideo} type="video/mp4"/>
    </video>
    <div class="container">
      <div class="row">
        <div class="col-xl-4">
          <Image src={logo} alt="logo" style={{width: '300px'}} className="d-flex align-items-center"/>
          <h2 data-aos="fade-up">Welcome to Ayoba Boreholes and water Solutions</h2>
          <blockquote data-aos="fade-up" data-aos-delay="100">
            <p>Get your very own borehole water source at home or let us install a municipal water backup system.</p>
          </blockquote>
       

        </div>
       
      </div>
    </div>
    <div className="wavesBg" style={{background: 'rgb(27 47 69 0.1)', zIndex: '9999', rotate: '0deg', bottom:'-5px'}}><HeroWave/></div>
  </section>
    );
}
export default HeroSection;