import React from "react";
import { Container, Nav, Navbar  } from "react-bootstrap";
import PhoneCallComponent from '../others/phoneCallComponent';
import WhatsAppButton from "../others/whatsappButtonComponent";
import logo from '../../assets/img/Ayobaboreholes_logo.png';
//import logo2 from '../../assets/img/SampleLogo2.png';
import './Header.css'
const Header = () => {
    return(
      <>     
        <header id="header" className="fixed-top d-flex align-items-center header-transparent">
          <Container className="px-0">
          <Navbar expand="lg" collapseOnSelect>
            <Navbar.Brand href="#home" className="ps-0 me-0 d-none d-sm-flex">
            <img
              alt=""
              src={logo}
              height="60"
              className="d-inline-block align-top me-0"
            />{' '}
            <div className="ps-2">
           <h5 className="mb-1">Ayoba Boreholes and Water Solutions.</h5>
           <p className="mb-0" style={{fontSize: '14px'}}>Unleashing Underground Potential</p>
           </div>
          </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" className="text-white p-2 align-items-end m-2 text-end" style={{borderColor: '#fff'}}>
              <i className="bi bi-list"></i>
              </Navbar.Toggle>
              <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="justify-content-end flex-grow-1 ps-2" id="navbar">
                <Nav.Link className="nav-link scrollto active" href="#hero">Home</Nav.Link>
                <Nav.Link className="nav-link scrollto" href="#about">About</Nav.Link>
                <Nav.Link className="nav-link scrollto" href="#drillingProcess">Drilling Process</Nav.Link>
                <Nav.Link className="nav-link scrollto" href="#services">Services</Nav.Link>
                <Nav.Link className="nav-link scrollto" href="#contact">Contact</Nav.Link>
                <PhoneCallComponent/>
                <WhatsAppButton/>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          </Container>
       </header>
       </>

    );
}
export default Header;